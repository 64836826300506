<template>
  <div>
    <fetcDocs base="" file="index" />
  </div>
</template>

<script>
import fetcDocs from '../components/fetch-docs';

export default {
  components: {
    fetcDocs
  },
  mounted () {
  }
};
</script>
